<template>
  <div id="app">
    <router-view/>


  </div>
</template>

<script>
export default {
  methods:{
  show(){
  this.$router.push("/home")
}
}

}

</script>

<style lang="less">
/* 隐藏滚动条，但保留滚动功能 */
::-webkit-scrollbar {
  display: none;
}

/* 完全禁用滚动条 */
body::-webkit-scrollbar {
  display: none;
}
</style>