import api from './request';
// import { resolve, reject } from "core-js/fn/promise";
import { Message } from 'element-ui'


/** get请求 **/
export function httpGet(url,params){
    if(!params){
        params={}
    }
    // params['time']=new Data().getTime()
    return new Promise((resolve,reject)=>{
        api.get(url,{params:params}).then((res) =>{
            resolve(res)
        }).catch((err) =>{
            reject(err)
        })
    })
}

/** get请求导出 **/
export function httpGetExcel(url,params){
    if(!params){
        params={}
    }
    // params['time']=new Data().getTime
    return new Promise((resolve,reject) =>{
        api.get(url,{
            params:params,
            responseType: 'blob'
        }).then((res) =>{
            resolve(res)
        }).catch((err) =>{
            reject(err)
        })
    })
}

/** post请求 **/
export function httpPost(url,data){
    return new Promise((resolve,reject) =>{
        api.post(url,data,{
            headers: {
                'Content-Type': 'application/json'
              },
            }).then((res) =>{
                resolve(res)
            }).catch((err) =>{
                reject(err)
            })
    })
}

/** pup请求 **/
export function httpPut(url,data){
    return new Promise((resolve,reject) =>{
        api.put(url,data,{
            headers: {
                'Content-Type': 'application/json'
              }
        }).then((res) =>{
            resolve(res)
        }).catch((err) =>{
            reject(err)
        })
    })
}

/** deletd请求 **/
export function httpDelete(url,data){
    return new Promise((resolve,reject) =>{
        api.delete(url,data,{
            headers: {
                'Content-Type': 'application/json'
              }
        }).then((res) =>{
            resolve(res)
        }).catch((err) =>{
            reject(err)
        })
    })
}

/** msg消息提醒 **/
export function errorMessage(res){
    if (res.code === -100){
        Message.error('系统错误')
    }else if (res.code === -101){
        Message.error(res.msg)
    }
}