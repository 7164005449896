import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {  //设置默认页面
    path: '/', 
    redirect: '/yyt56'
  },
  {
    path: '/privacy',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/yyt56',
    name: 'yyt56',
    component: () => import('../views/News-page.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
