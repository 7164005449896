// 导入vue
import Vue from 'vue'

// 引入组件
import { Button, Icon ,Sticky } from 'vant'

// 使用组件
Vue.use(Button)
Vue.use(Icon)

Vue.use(Sticky); //吸顶
