import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//挂载axios
import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = "/"

//导入js文件 根据窗口调整html-font-size大小
import 'amfe-flexible'

// 加载全局样式
import './styles/index.less'

// 导入vant组件
import './utils/vant.js'

// 全局 API 
import * as utils from './utils/utils'
for (const i in utils) {
  Vue.prototype[i] = utils[i]
}

//挂载Element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


//行内转换 rem
function px2rem(px){
  if(/%/gi.test(px)) {
  //有百分号%，特殊处理，表述pc是一个有百分号的数，比如:9日%return px;
  }else {
  return parseFloat(px)/ 75 + "rem";
  }}
  Vue.prototype.$px2rem = px2rem;// 放到全局